<template>
    <div class="page-wrapper">
        <v-snackbar
                v-model="notification"
                :color="notification_type"
                :timeout="5000"
                :top="true"
        >
            {{ notification_message }}
            <v-btn
                    dark
                    text
                    @click="notification = false"
            >
                Close
            </v-btn>
        </v-snackbar>

        <v-container
                fill-height
                fluid
                grid-list-xl>
            <v-layout
                    justify-center
                    wrap>
                <v-flex
                        xs12
                        md12
                >
                    <material-card
                            color="primary"
                            class="darken-1"
                            title="Editing User"
                            text=""
                    >

                        <form @submit.prevent="submit">
                            <v-text-field
                                    v-model="profile.website"
                                    :counter="300"
                                    :error-messages="checkWebsite"
                                    label="Website"
                                    required
                                    @input="$v.profile.website.$touch()"
                                    @blur="$v.profile.website.$touch()"
                            ></v-text-field>

                            <v-text-field
                                    v-model="profile.user.first_name"
                                    :counter="40"
                                    :error-messages="checkFirstName"
                                    label="First Name"
                                    required
                                    @input="$v.profile.user.first_name.$touch()"
                                    @blur="$v.profile.user.first_name.$touch()"
                            ></v-text-field>

                            <v-text-field
                                    v-model="profile.user.last_name"
                                    label="Last Name"
                                    required
                                    :error-messages="checkLastName"

                                    @input="$v.profile.user.last_name.$touch()"
                                    @blur="$v.profile.user.last_name.$touch()"
                            ></v-text-field>
                            <v-text-field
                                    v-model="profile.user.username"
                                    label="Username / Email Address"
                                    required
                                    :error-messages="checkUsername"

                                    @input="$v.profile.user.username.$touch()"
                                    @blur="$v.profile.user.username.$touch()"
                            ></v-text-field>

                            <div v-if="id === 0">
                                <v-text-field
                                        v-model="profile.user.password"
                                        label="password"
                                        required
                                        :error-messages="checkPassword"

                                        @input="$v.profile.user.password.$touch()"
                                        @blur="$v.profile.user.password.$touch()"
                                ></v-text-field>
                            </div>


                            <v-text-field
                                    v-model="profile.address"
                                    label="Address"
                                    required
                                    :error-messages="checkAddress"

                                    @input="$v.profile.address.$touch()"
                                    @blur="$v.profile.address.$touch()"
                            ></v-text-field>

                            <v-text-field
                                    v-model="profile.region"
                                    label="Region"
                                    required
                                    :error-messages="checkRegion"

                                    @input="$v.profile.region.$touch()"
                                    @blur="$v.profile.region.$touch()"
                            ></v-text-field>

                            <v-text-field
                                    v-model="profile.zip_code"
                                    label="Zip Code"
                                    required
                                    :error-messages="checkZipCode"

                                    @input="$v.profile.zip_code.$touch()"
                                    @blur="$v.profile.zip_code.$touch()"
                            ></v-text-field>
                            <div style="margin-bottom:45px;">
                                <v-autocomplete
                                        v-model="profile.country_id"
                                        :loading="loading"
                                        :items="countries"
                                        item-text="name"
                                        item-value="id"
                                        :search-input.sync="search_countries"
                                        cache-items
                                        class="mx-4"
                                        flat
                                        hide-no-data
                                        hide-details
                                        label="Country"
                                ></v-autocomplete>
                            </div>

                            <v-text-field
                                    v-model="profile.phone"
                                    label="Phone N"
                                    required
                                    :error-messages="checkPhone"

                                    @input="$v.profile.phone.$touch()"
                                    @blur="$v.profile.phone.$touch()"
                            ></v-text-field>
                            <v-text-field
                                    v-model="profile.phone_extension"
                                    label="Phone Ext"
                                    required
                                    :error-messages="checkPhoneExt"

                                    @input="$v.profile.phone.$touch()"
                                    @blur="$v.profile.phone.$touch()"
                            ></v-text-field>
                            <v-text-field
                                    v-model="profile.mobile"
                                    label="Mobile No"
                                    required
                                    :error-messages="checkMobile"
                                    @input="$v.profile.mobile.$touch()"
                                    @blur="$v.profile.mobile.$touch()"
                            ></v-text-field>

                            <v-select
                                    v-model="profile.user.groups"
                                    :items="user_groups"
                                    :chips="true"
                                    :multiple="true"
                                    item-value="id"
                                    item-text="name"
                                    :menu-props="{closeOnClick: true, closeOnContentClick: true}"
                                    label="Auth Groups"
                            >
                            </v-select>

                            <v-select
                                    v-model="profile.state"
                                    :items="states"
                                    item-value="id"
                                    item-text="name"
                                    label="state"
                                    :error-messages="checkState"

                                    required
                                    @change="$v.profile.state.$touch()"
                                    @blur="$v.profile.state.$touch()"
                            ></v-select>


                            <v-btn class="mr-4"
                                   block
                                   color="primary"
                                   @click="save_profile">
                                Save
                            </v-btn>
                        </form>
                    </material-card>
                </v-flex>
            </v-layout>
        </v-container>

    </div>
</template>


<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email, url} from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],

        validations: {
            profile: {
                user: {
                    first_name: {
                        required, maxLength: maxLength(40)
                    },
                    last_name: {
                        required, maxLength: maxLength(40)
                    },
                    password: {
                        required,
                        maxLength: maxLength(40),
                        minLength: minLength(6)
                    },
                    username: {
                        required, email
                    }
                },
                website: {required, url, maxLength: maxLength(300)},
                state: {required},
                address: {
                    required,
                    maxLength: maxLength(400),
                    minLength: minLength(10)
                },
                region: {required},
                zip_code: {required, maxLength: maxLength(12)},
                phone: {required, minLength: minLength(6)},
                mobile: {required, minLength: minLength(6)},
                phone_extension: {required, maxLength: maxLength(6)},
            }
        },

        components: {},
        data() {
            return {
                profile: {
                    user: {
                        groups: []
                    }
                },
                countries: [],
                search_countries: null,
                submitStatus: null,
                isLoading: false,
                loading: false,
                states: [
                    {
                        id: 1,
                        name: 'Active',
                    },
                    {
                        id: 2,
                        name: 'Disabled'
                    }
                ],
                user_groups: [
                    {
                        id: 1,
                        name: 'Developer',
                    },
                    {
                        id: 2,
                        name: 'Admin',
                    },
                    {
                        id: 19,
                        name: 'Business Inelegance',
                    },
                    {
                        id: 5,
                        name: 'Organisation / Customer',
                    },
                    {
                        id: 6,
                        name: 'Organisation / Factory',
                    },
                    {
                        id: 9,
                        name: 'Sales',
                    },
                    {
                        id: 11,
                        name: 'Sales Manager',
                    },
                    {
                        id: 13,
                        name: 'Organisation / Partner',
                    },
                    {
                        id: 3,
                        name: 'Sourcer',
                    },
                    {
                        id: 17,
                        name: 'Sourcing Manager',
                    },

                ],
                checkbox: false,
                id: 0,
                notification: false,
                notification_message: '',
                notification_type: 'success',
                default_prof: {
                    user: {
                        first_name: 'test',
                        last_name: 'test',
                        id: 0,
                        username: 'testyu1234@qweqw.com',
                        password: '123456'
                    },
                    address: 'asdasdsadasdassadas',
                    region: '1212132131231',
                    country: 1,
                    phone: '1231313',
                    website: 'http://erp.wellstar.com',
                    zip_code: 'asdasdasd',
                    phone_extension: '134342',
                    mobile: '1232141',
                    state: 1
                }
            }
        },
        computed: {
            checkWebsite() {
                const errors = [];
                if (!this.$v.profile.website.$dirty) return errors;
                !this.$v.profile.website.maxLength && errors.push('website must be at most 300 characters long');
                !this.$v.profile.website.url && errors.push('website must be a valid url etc: http://www.wellstartrading.com:');
                !this.$v.profile.website.required && errors.push('website is required.');
                return errors;
            },
            checkFirstName() {
                const errors = [];
                if (!this.$v.profile.user.first_name.$dirty) return errors;
                !this.$v.profile.user.first_name.maxLength && errors.push('First Name must be at most 40 characters long');
                !this.$v.profile.user.first_name.required && errors.push('First Name is required.');
                return errors;
            },
            checkPassword() {
                const errors = [];
                if (!this.$v.profile.user.password.$dirty) return errors;
                !this.$v.profile.user.password.maxLength && errors.push('password must be at most 40 characters long');
                !this.$v.profile.user.password.minLength && errors.push('password must be 6 characters long at least');
                !this.$v.profile.user.password.required && errors.push('password is required.');
                return errors;
            },
            checkLastName() {
                const errors = [];
                if (!this.$v.profile.user.last_name.$dirty) return errors;
                !this.$v.profile.user.last_name.maxLength && errors.push('Last Name must be at most 40 characters long');
                !this.$v.profile.user.last_name.required && errors.push('Last Name is required.');
                return errors;
            },
            checkAddress() {
                const errors = [];
                if (!this.$v.profile.address.$dirty) return errors;
                !this.$v.profile.address.minLength && errors.push('Address must be at least 10 characters long');
                !this.$v.profile.address.maxLength && errors.push('Address must be at most 400 characters long');
                !this.$v.profile.address.required && errors.push('Address is required.');
                return errors;
            },
            checkRegion() {
                const errors = [];
                if (!this.$v.profile.region.$dirty) return errors;
                !this.$v.profile.region.required && errors.push('Address is required.');
                return errors;
            },
            checkZipCode() {
                const errors = [];
                if (!this.$v.profile.zip_code.$dirty) return errors;
                !this.$v.profile.zip_code.required && errors.push('zip_code is required.');
                !this.$v.profile.zip_code.maxLength && errors.push('zip_code must be at most 12 characters long');
                return errors;
            },
            checkPhone() {
                const errors = [];
                if (!this.$v.profile.phone.$dirty) return errors;
                !this.$v.profile.phone.required && errors.push('phone is required.');
                !this.$v.profile.phone.minLength && errors.push('phone must be at least 6 chars long');
                return errors;
            },
            checkMobile() {
                const errors = [];
                if (!this.$v.profile.mobile.$dirty) return errors;
                !this.$v.profile.mobile.required && errors.push('Mobile is required.');
                !this.$v.profile.mobile.minLength && errors.push('Mobile must be at least 6 chars long');
                return errors;
            },
            checkPhoneExt() {
                const errors = [];
                if (!this.$v.profile.phone_extension.$dirty) return errors;
                !this.$v.profile.phone_extension.required && errors.push('phone_extension is required.');
                !this.$v.profile.phone_extension.maxLength && errors.push('phone_extension can be at most 6 chars long');
                return errors;
            },
            checkUsername() {
                const errors = [];
                if (!this.$v.profile.user.username.$dirty) return errors;
                !this.$v.profile.user.username.email && errors.push('Must be valid e-mail');
                !this.$v.profile.user.username.required && errors.push('Username is required');
                return errors;
            },
            checkState() {
                const errors = [];
                if (!this.$v.profile.state.$dirty) return errors;
                !this.$v.profile.state.required && errors.push('Item is required');
                return errors;
            },
        },
        created() {
            this.id = parseInt(this.$route.params.id);
            if (this.id > 0) {
                this.load_profile();
            } else {
                // this.profile = this.default_prof;
            }

        },
        watch: {
            search_countries(val) {

                if (!val || val === '') return;

                if (this.isLoading) return;

                this.isLoading = true;
                console.log('reload');
                this.$http.get(
                    this.$http.defaults.baseURL + '/admins/countries/?name=' + val).then((response) => {
                    this.countries = response.data.results;
                    this.isLoading = false;
                })
                    .catch((error) => {
                        // this.notification = false;
                        // this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    }).finally(() => (this.isLoading = false));
            },
        },
        methods: {
            save_profile() {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    this.notification = true;
                    this.notification_type = 'red darken-2';
                    this.notification_message = 'Please fill the form out correctly';
                    return false;
                }
                let groups = [];
                this.profile.user.groups.forEach(function (o, v) {
                    if (typeof o.id !== 'undefined') {
                        groups.push(o.id);
                    } else {
                        groups.push(o);
                    }
                });

                this.profile.user.groups = groups;
                this.profile.user_id = this.profile.user.id;

                console.log(this.profile);
                if (this.id && this.id > 0) {
                    this.$http.patch(
                        this.$http.defaults.baseURL + '/admins/user-profiles-default/' + this.id + '/', this.profile).then((response) => {
                        console.log(response);
                        this.notification = true;
                        this.save_user(this.profile.user);

                        this.notification_message = 'Successfully Saved';
                        this.notification_type = 'success';
                    })
                        .catch((error) => {
                            this.notification = true;
                            this.notification_message = 'Error: Please contact administrator';
                            this.notification_type = 'red darken-2';


                            console.log("Error");
                            console.log(error);
                        });
                } else {
                    this.save_user(this.profile.user);
                }

            },
            save_user(user) {
                console.log("USER");
                user.email = user.username;

                if (typeof user.id === 'undefined' || user.id === 0) {
                    user.id = 0;

                    return this.$http.post(
                        this.$http.defaults.baseURL + '/admins/user-profiles-user/', user).then((response) => {
                        this.profile.user = response.data;
                        console.log("USER SAVED");
                        this.profile.user = this.profile.user.id;
                        this.$http.post(
                            this.$http.defaults.baseURL + '/admins/user-profiles-default/', this.profile).then((response) => {
                            this.profile = response.data;
                            console.log("PROFILE SAVED");
                            this.$router.push('/c-panel/users');

                            this.notification = true;
                            this.notification_message = 'Successfully Saved';
                            this.notification_type = 'success';
                        })
                            .catch((error) => {
                                this.notification = true;
                                this.notification_message = 'Error: Please contact administrator';
                                this.notification_type = 'red darken-2';
                                console.log("Error");
                                console.log(error);
                            });
                    })
                        .catch((error) => {
                            this.notification = true;
                            this.notification_message = 'Error: Please contact administrator';
                            console.log("Error");
                            console.log(error);
                        });
                } else {
                    this.$http.post(
                        this.$http.defaults.baseURL + '/admins/user-profiles-user/', user).then((response) => {
                        console.log(response);
                    })
                        .catch((error) => {
                            this.notification = true;
                            this.notification_message = 'Error: Please contact administrator';
                            console.log("Error");
                            console.log(error);
                        });
                }

            },
            load_profile() {
                this.$http.get(
                    this.$http.defaults.baseURL + '/admins/user-profiles-default/' + this.id).then((response) => {
                    this.countries.push(response.data.country_data);
                    this.profile = response.data;
                    this.profile.user.password = '123456';
                    this.notification = false;
                    this.notification_message = '';
                    console.log(this.profile);
                    console.log(this.countries);
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
        },
    }
</script>
